/**
 * Container -Component
 */

.cmp-linklist__container-heading {
  margin-bottom: var(--spacing-lg);
}

/* Background color - Policies */

/* Adds spacing to container if it has neutral-lighter background color */
.cmp-container--background-neutral-lighter > .cmp-container {
  padding: var(--spacing-xl) 0;
}

.cmp-container--background-primary,
.cmp-container--full-width.cmp-container--background-primary .cmp-container {
  background-color: var(--color-primary-500);
}

.cmp-container--background-black,
.cmp-container--full-width.cmp-container--background-black .cmp-container {
  background-color: var(--color-black);
}

.cmp-container--background-neutral-light,
.cmp-container--full-width.cmp-container--background-neutral-light .cmp-container {
  background-color: var(--color-gray-100);
}

.cmp-container--background-neutral-lighter,
.cmp-container--full-width.cmp-container--background-neutral-lighter .cmp-container {
  background-color: var(--color-gray-50);
}

.cmp-container--background-neutral-dark,
.cmp-container--full-width.cmp-container--background-neutral-dark .cmp-container {
  background-color: var(--color-gray-500);
}

.cmp-container--full-width {
  background-color: transparent;
}

/* Text color - Policies */
.cmp-container--color-white {
  color: var(--color-white);
}

.cmp-container--color-neutral-darkest {
  color: var(--color-gray-900);
}

/* Spacing - Policies */
.cmp-container--space-top {
  margin-top: var(--spacing-xxl);
}

.cmp-container--space-bottom {
  margin-bottom: var(--spacing-4xl);
}

@media (--breakpoint-down-md) {
  .cmp-container--space-top {
    margin-top: var(--spacing-xl);
  }

  .cmp-container--space-bottom {
    margin-bottom: var(--spacing-xl);
  }
}
