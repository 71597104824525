.cmp-spacer {
  width: 100%;
  margin: 0;
  padding: 0;

  /* stylelint-disable declaration-no-important */
  & > *::after {
    padding: 0 !important;
  }
  /* stylelint-enable declaration-no-important */

  &--xl {
    height: var(--spacing-xl);
  }

  &--lg {
    height: var(--spacing-lg);
  }

  &--md {
    height: var(--spacing-md);
  }

  &--sm {
    height: var(--spacing-sm);
  }

  &--xs {
    height: var(--spacing-xs);
  }

  &--xxs {
    height: var(--spacing-xxs);
  }
}

@include remove-grid-content('.hidden, .overflowed');
