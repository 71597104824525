.c-select {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 0 var(--spacing-md);
  flex: 1;
}

.c-select__select {
  width: 100%;
  appearance: none;
  position: relative;
  background: white;
  font-family: inherit;
  font-size: inherit;
  padding: calc(var(--spacing-xs) - var(--spacing-xxxs)) var(--spacing-md);
  padding-right: var(--spacing-xl);
  line-height: var(--line-height);
  border-radius: 0;
  border: var(--spacing-xxxs) solid var(--color-gray-700);
  height: 2.5rem;
}

.c-select::after {
  font-family: var(--icon-font);
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* include icon font  */
  width: var(--spacing-xl);
  font-size: var(--spacing-lg);
  content: var(--icon-keyboard_arrow_down);
  right: 0;
  top: 0;
  line-height: var(--spacing-xxl);
  pointer-events: none;
  position: absolute;
}

.c-select__select:disabled {
  background: var(--color-gray-50);
  border-color: var(--color-gray-300);
  color: var(--neutral-darkest);
}
