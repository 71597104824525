/*
* cookie settings page
* overwrite ot styles
*/

/* stylelint-disable selector-max-id */

/* cookie settings Button  */
#ot-sdk-btn.ot-sdk-show-settings.ot-sdk-show-settings.ot-sdk-show-settings {
  @include bold-lg();

  background-color: var(--color-primary-500);
  border: var(--spacing-xxxs) solid var(--color-primary-500);
  color: var(--color-white);
  padding: var(--spacing-sm) var(--spacing-xl);
  align-self: flex-start;
}

#ot-sdk-btn.ot-sdk-show-settings.ot-sdk-show-settings.ot-sdk-show-settings:hover {
  background-color: var(--color-primary-700);
}
