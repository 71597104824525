.cmp-cta {
  min-height: 20.125rem;
  height: auto;
  background-color: var(--color-primary-500);
  color: var(--color-white);
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: var(--spacing-4xl);
}

/* carousel component specific style for the cta */
.carousel .cmp-cta {
  margin-bottom: 0;
}

.cmp-cta__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  text-align: center;
  padding: 0.5rem 22%;
}

.cmp-cta__icon {
  z-index: 1;
  font-size: var(--font-size-icon-sm);
}

.cmp-cta__headline {
  margin: var(--spacing-sm) 0;
  z-index: 1;
}

.cmp-cta__title {
  margin-bottom: var(--spacing-sm);
  z-index: 1;
}

.cmp-cta__description {
  font-size: var(--font-size-icon-xs);
  line-height: var(--line-height-md);
  z-index: 1;
}

.cmp-cta__image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cmp-cta__image::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(0deg, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)); /* W3C */
}

.cmp-cta__button {
  margin-top: var(--spacing-xl);
  z-index: 0;
}

@media (--breakpoint-down-xxl) {
  .cmp-cta--full-width {
    margin: var(--spacing-xl) var(--grid-anti-margin);
    width: auto;
  }

  .calltoaction:has(+ div .cmp-title, + div .cmp-title-v2) .cmp-cta--full-width {
    margin-bottom: var(--spacing-4xl);
  }
}

@media (--breakpoint-down-md) {
  .cmp-cta__content {
    padding: var(--spacing-xxl) var(--spacing-xl);
  }
}
