/*
* Utilities:
* Misc
*/

.root .u-visually-hidden {
  position: absolute;
  clip: rect(var(--spacing-xxxs) var(--spacing-xxxs) var(--spacing-xxxs) var(--spacing-xxxs));
  clip-path: inset(0 0 99.9% 99.9%);
  overflow: hidden;
  height: var(--spacing-xxxs);
  width: var(--spacing-xxxs);
  padding: 0;
  border: 0;
}

.root .u-list--unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.root .u-display--flex {
  display: flex;
}

.root .u-display--inline-flex {
  display: inline-flex;
}

.root .u-display--inline-block {
  display: inline-block;
}

.root .u-float--right {
  float: right;
}

.root .u-float--left {
  float: left;
}

.root .u-vertical-align--middle {
  vertical-align: middle;
}

.u-noscroll {
  overflow: hidden;
}

.root .u-text-decoration--underline {
  text-decoration: underline;
}

/*
  Visually hides content but leaves it readable by screen readers
  https://a11y-guidelines.orange.com/en/web/components-examples/accessible-hiding/
*/
.sr-only {
  position: absolute;
  width: var(--spacing-xxxs);
  height: var(--spacing-xxxs);
  padding: 0;
  margin: calc(var(--spacing-xxxs) * -1);
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
