/*
* Utilities:
* Colors
*/

.u-color--primary {
  color: var(--color-primary-500);
}


.u-color--secondary {
  color: var(--color-secondary);
}

.u-color--secondary-light {
  color: var(--color-secondary-light);
}


/* neutrals  */
.u-color--neutral-darkest {
  color: var(--color-gray-900);
}

.u-color--neutral-darker {
  color: var(--color-gray-700);
}

.u-color--neutral-dark {
  color: var(--color-gray-500);
}

.u-color--neutral {
  color: var(--color-gray-300);
}

.u-color--neutral-light {
  color: var(--color-gray-100);
}

.u-color--neutral-lighter {
  color: var(--color-gray-50);
}

.u-color--black {
  color: black;
}

.u-color--white {
  color: white;
}

/* states  */
.u-color--success {
  color: var(--color-success-500);
}

.u-color--error {
  color: var(--color-error-500);
}



/*
* Background colors
*/

.u-background--primary {
  background: var(--color-primary-500);
}


.u-background--secondary {
  background: var(--color-secondary);
}

.u-background--secondary-light {
  background: var(--color-secondary-light);
}


/* neutrals  */
.u-background--neutral-darkest {
  background: var(--color-gray-900);
}

.u-background--neutral-darker {
  background: var(--color-gray-700);
}

.u-background--neutral-dark {
  background: var(--color-gray-500);
}

.u-background--neutral {
  background: var(--color-gray-300);
}

.u-background--neutral-light {
  background: var(--color-gray-100);
}

.u-background--neutral-lighter {
  background: var(--color-gray-50);
}

.u-background--black {
  background: black;
}

.u-background--white {
  background: white;
}

/* states  */
.u-background--success {
  background: var(--color-success-500);
}

.u-background--error {
  background: var(--color-error-500);
}
