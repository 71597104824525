/* fix extra scroll bar in IE and edge - ABB-1056 */
/* stylelint-disable-next-line */
.otFlat#onetrust-banner-sdk {
  /* stylelint-disable-line */
  overflow: hidden;
}

/* fix firefox rendering issue ABB-1057 */
/* stylelint-disable selector-max-id */
#onetrust-button-group .cookie-setting-link.cookie-setting-link.cookie-setting-link,
#onetrust-button-group .cookie-setting-link.cookie-setting-link.cookie-setting-link:hover {
  opacity: 1;
}

#onetrust-banner-sdk {
  #onetrust-group-container #onetrust-policy-text a {
    color: var(--color-primary-500);
    outline: none;
    text-decoration: none;

    &:hover {
      color: var(--color-primary-700);
      text-decoration: underline;
    }

    &:active {
      color: var(--color-primary-800);
      text-decoration: underline;
    }
  }

  #onetrust-button-group-parent {
    .banner-actions-container button {
      border-radius: 0;
      /* stylelint-disable-next-line declaration-no-important */
      border: none !important;

      @include button-secondary;
    }

    #onetrust-pc-btn-handler {
      color: var(--color-primary-500);
      outline: none;
      text-decoration: none;

      &:hover {
        color: var(--color-primary-700);
        text-decoration: underline;
      }

      &:active {
        color: var(--color-primary-800);
        text-decoration: underline;
      }
    }
  }
}
