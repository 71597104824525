/**
 * Title -Component
 */

@include remove-grid-content('.title');

.cmp-title {
  &__link:hover {
    text-decoration: underline;
  }

  &--primary {
    /* Visual style - Policies */
    .cmp-title__text {
      margin-bottom: var(--spacing-xl);

      @include heading-1();
    }
  }

  &--section {
    .cmp-title__text {
      margin-bottom: var(--spacing-xl);
    }
  }

  &--paragraph .cmp-title__text {
    @include bold-lg();

    margin-bottom: var(--spacing-sm);
  }
  /* Text color - Policies */

  &--color-white {
    color: var(--color-white);
  }
  /* Alignment - Policies */

  &--left-align {
    text-align: left;
  }

  &--center-align {
    text-align: center;
  }

  &-right-align {
    text-align: right;
  }
}

@media #{$mq-mobile} {
  .cmp-title--primary .cmp-title__text {
    margin-bottom: var(--spacing-md);

    @include heading-1();
  }

  .cmp-title--section .cmp-title__text {
    margin-bottom: var(--spacing-md);
  }

  .cmp-title--paragraph .cmp-title__text {
    @include bold-lg();

    margin-bottom: var(--spacing-xs);
  }
}

/* Perspective template title styles */
.perspectives {
  /* stylelint-disable-next-line selector-no-qualifying-type */
  h1.cmp-title__text {
    text-transform: uppercase;
  }

  .cmp-title h2 {
    color: var(--color-gray-900);
    text-transform: uppercase;
  }

  .cmp-title--color-white .cmp-title h2 {
    color: var(--color-white);
  }
}
