.cmp-footercmp {
  color: var(--color-white);
  position: relative;
}

.cmp-footercmp p,
.cmp-footercmp ul {
  margin: 0;
  padding: 0;
}

.cmp-footercmp--background-primary {
  background-color: var(--color-primary-500);
}

.cmp-footercmp--background-neutral-lighter {
  background-color: var(--color-gray-50);
}

.cmp-footercmp--background-neutral-light {
  background-color: var(--color-gray-100);
}

.cmp-footercmp--background-neutral-dark {
  background-color: var(--color-gray-500);
}

.cmp-footercmp .cmp-footercmp__title-text {
  @include bold-lg();

  margin-bottom: var(--spacing-xs);
}

.cmp-footercmp .cmp-text a {
  text-decoration: none;
  color: var(--color-gray-900);
}

.cmp-footercmp .button {
  text-align: center;
}

.cmp-footercmp .separator {
  margin: var(--spacing-lg) 0;
}

.cmp-footercmp .separator hr {
  margin: 0;
}

.cmp-footercmp .cmp-footercmp__button {
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  align-items: center;
}

/* General for Web, Intranet and PowerTv */

.cmp-footercmp.cmp-footercmp--web .cmp-button--round,
.cmp-footercmp.cmp-footercmp--intranet .cmp-button--round,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-button--round {
  position: unset;
  transform: none;
  box-shadow: none;
  animation: link-hover-reverse 0.15s ease-in-out 0s 1 forwards;
  outline: var(--border-xs) solid transparent;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 0;

  &:has(.cmp-footercmp__button:hover) {
    animation: link-hover 0.15s ease-in-out 0s 1 forwards;
    outline: var(--border-xs) solid var(--color-primary-500);
    background-color: var(--color-primary-700);
  }

  &:has(.cmp-footercmp__button:active) {
    animation: link-hover-reverse 0.15s ease-in-out 0s 1 forwards;
    outline: var(--border-xs) solid var(--color-primary-500);
    background-color: var(--color-primary-800);
  }

  .cmp-footercmp__button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    height: 100%;
    width: 100%;
  }
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__contact-button .cmp-button__icon {
  margin-right: var(--spacing-md);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__button,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__button,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__button {
  .cmp-button__icon,
  &:active .cmp-button__icon {
    animation: link-icon-hover-reverse 0.15s ease-in-out 0s 1 forwards;
  }

  &:hover .cmp-button__icon {
    animation: link-icon-hover 0.15s ease-in-out 0s 1 forwards;
  }
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__button:hover .cmp-footercmp__button-text {
  text-decoration: underline;
  color: var(--color-white);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon .cmp-footercmp__button {
  color: var(--color-gray-900);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__social-icon,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__social-icon {
  margin: 0 0.313rem;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon:first-child,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__social-icon:first-child,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__social-icon:first-child {
  margin-left: 0;
}

.cmp-footercmp__button-back-to-top {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--spacing-4xl);
  margin-bottom: var(--spacing-xs);

  .cmp-button__icon {
    margin-top: 0;
  }
}

.cmp-footercmp__link-columns {
  display: flex;
  align-items: self-start;
  justify-content: space-evenly;
  padding: 3.125rem 0;
}

.cmp-footercmp__link-columns-left {
  margin-left: 5%;
}

.cmp-footercmp__link-columns-separator {
  display: none;
}

.cmp-footercmp__bottom-media-links {
  @include regular-md();

  display: flex;
  justify-content: space-between;
  padding: 0.625rem 2.5rem;
}

.cmp-footercmp__bottom-links {
  display: flex;
  align-items: self-start;
}

.cmp-footercmp .cmp-footercmp__rich-text-wrapper a:hover {
  text-decoration: underline;
}

/* CMP-FOOTER WEB */

.cmp-footercmp.cmp-footercmp--web .cmp-list__item-link,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__title,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text a {
  color: var(--color-gray-900);
}

.cmp-footercmp.cmp-footercmp--web .cmp-list__item:hover,
.cmp-footercmp.cmp-footercmp--web .cmp-list__item-link:hover {
  color: var(--color-primary-700);
}

.cmp-footercmp.cmp-footercmp--web .cmp-list__item:active,
.cmp-footercmp.cmp-footercmp--web .cmp-list__item-link:active {
  color: var(--color-primary-800);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links {
  list-style: none;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text {
  margin-right: var(--spacing-xxl);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text:hover,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__rich-text--copyrightsLink .cmp-text a:hover {
  text-decoration: underline;
  color: var(--color-primary-700);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .text:last-child .cmp-text {
  margin-right: 0;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__contact-button {
  height: 3.188rem;
  display: flex;
  align-items: center;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom .text--mobile {
  margin-bottom: 1.563rem;
}

/* INTRANET AND POWER TV */

.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__contact-button,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__link-columns-wrapper .cmp-footercmp__title-text,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__bottom-links,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrightsLink,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__contact-button,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__link-columns-wrapper .cmp-footercmp__title-text,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__bottom-links,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrightsLink,
.cmp-footercmp__mobile {
  display: none;
}

@media (--breakpoint-down-sm) {
  .cmp-footercmp__link-columns {
    padding: 1.875rem 1.25rem;
    flex-wrap: wrap;
  }

  .cmp-footercmp__link-columns-left,
  .cmp-footercmp__link-columns-middle {
    flex: 0 0 50%;
    margin: 0;
  }

  .cmp-footercmp__link-columns-separator {
    width: 100%;
    display: block;
    margin-top: 1.875rem;
  }

  .cmp-footercmp__social-icons-wrapper {
    flex: 0 0 100%;
    margin-top: 1.25rem;
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom {
    position: relative;
  }

  .cmp-footercmp__bottom-media-links {
    @include regular-sm();

    flex-direction: column;
    text-align: center;
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links {
    @include regular-sm();

    justify-content: center;
    flex-wrap: wrap;
    margin: var(--spacing-sm) 0;
    z-index: 2;
  }

  .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrightsLink {
    border: var(--spacing-xxxs) solid #2d2d2d;
    padding: 0.625rem 0;
  }

  .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrights {
    line-height: var(--sm-line-height-sm);
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text {
    margin-right: 0;
    padding: 0 0.313rem;
    border-right: var(--spacing-xxxs) solid var(--color-gray-300);
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links li:last-child .cmp-text {
    border: 0;
  }

  .cmp-footercmp__button-back-to-top {
    margin-right: var(--spacing-xl);
  }

  .cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__social-icon-list,
  .cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__social-icon-list {
    display: flex;
    justify-content: center;
  }

  .cmp-footercmp__mobile {
    display: block;
  }

  .cmp-footercmp__desktop {
    display: none;
  }
}
