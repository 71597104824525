@include remove-grid-content('.title-v2');

.cmp-title-v2 {
  @media #{$mq-desktop} {
    padding-bottom: var(--spacing-xl);
  }

  @media #{$mq-mobile} {
    padding-bottom: var(--spacing-md);
  }

  @media #{$mq-6} {
    width: grid-spacing(6, 8, 7);
  }

  @media #{$mq-5} {
    width: grid-spacing(5, 8, 7);
  }

  @media #{$mq-4} {
    width: grid-spacing(4, 8, 7);
  }

  @media #{$mq-3} {
    width: grid-spacing(3, 8, 7);
  }

  &--is-center-aligned {
    text-align: center;
  }

  &--is-left-aligned {
    text-align: left;
  }

  &--is-emphasised {
    .cmp-title-v2__pretitle {
      text-transform: uppercase;

      @include bold-lg();
    }

    .cmp-title-v2__title {
      @media #{$mq-desktop} {
        @include bold-4xl();
      }

      @media #{$mq-mobile} {
        @include bold-3xl();
      }
    }
  }

  &--is-normal {
    .cmp-title-v2__pretitle {
      text-transform: uppercase;

      @media #{$mq-desktop} {
        @include regular-sm();
      }

      @media #{$mq-mobile} {
        @include regular-xs();
      }
    }

    .cmp-title-v2__title {
      @media #{$mq-desktop} {
        @include regular-4xl();
      }

      @media #{$mq-mobile} {
        @include regular-3xl();
      }
    }
  }

  .cmp-title-v2__title {
    margin-bottom: 0;
  }

  .cmp-title-v2__pretitle {
    margin-bottom: var(--spacing-xxs);
  }

  .cmp-title-v2__short-text {
    @include regular-lg();

    @media #{$mq-desktop} {
      margin-top: var(--spacing-lg);
    }

    @media #{$mq-mobile} {
      margin-top: var(--spacing-md);
    }
  }
}

.title-v2 {
  display: flex;
}

.title-v2:has(.cmp-title-v2--is-center-aligned) {
  justify-content: center;
}
