/**
 * Button - Component
 */
@include remove-grid-content('.button');

.button {
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
}

.cmp-button {
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  .cmp-button__icon {
    @include transition-transform;
  }

  .cmp-button__icon--arrow_left,
  .cmp-button__icon--keyboard_arrow_left {
    @include transition-transform;
  }

  .cmp-button__icon--arrow_right,
  .cmp-button__icon--keyboard_arrow_right {
    @include transition-transform;
  }

  .cmp-button__icon--arrow_up,
  .cmp-button__icon--keyboard_arrow_up,
  .cmp-button__icon--back-to-top {
    @include transition-transform;
  }

  .cmp-button__icon--arrow_down,
  .cmp-button__icon--keyboard_arrow_down,
  .cmp-button__icon--download {
    @include transition-transform;
  }

  &:hover {
    color: var(--color-gray-900);

    .cmp-button__icon {
      @include link-icon-hover;
    }

    .cmp-button__icon--arrow_left,
    .cmp-button__icon--keyboard_arrow_left {
      @include link-icon-left;
    }

    .cmp-button__icon--arrow_right,
    .cmp-button__icon--keyboard_arrow_right {
      @include link-icon-right;
    }

    .cmp-button__icon--arrow_up,
    .cmp-button__icon--keyboard_arrow_up,
    .cmp-button__icon--back-to-top {
      @include link-icon-up;
    }

    .cmp-button__icon--arrow_down,
    .cmp-button__icon--keyboard_arrow_down,
    .cmp-button__icon--download {
      @include link-icon-down;
    }

    .cmp-button__text:not(.cmp-cta__link) {
      text-decoration: underline;
    }
  }
}

main .cmp-button {
  color: var(--color-primary-500);
  line-height: var(--line-height);
  transform: scale(1);

  @include transition-transform;

  .cmp-button__link-icon {
    @include transition-transform;
  }

  &:hover .cmp-button__link-icon {
    @include link-icon-right;
  }
}

main .cmp-button__link-icon {
  display: inline-flex;
  vertical-align: middle;
  font-size: var(--font-size-icon-md);
}

main .cmp-button__link-icon::after {
  font-family: 'custom_materialicons', sans-serif;
  vertical-align: middle;
  content: var(--icon-keyboard_arrow_right);
  display: inline-flex;
}

.cmp-button a {
  display: inline-flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
}

.cmp-button__text,
.cmp-form-button {
  @include bold-lg();
}

.cmp-button__icon {
  font-family: 'custom_materialicons', sans-serif;
  font-size: var(--font-size-icon-md);
  vertical-align: middle;
  position: relative;
  display: inline-flex;
  margin-top: toRem(2px);
}

.cmp-button__icon::after {
  display: inline-flex;
}

.cmp-button__text,
.cmp-button__icon {
  pointer-events: none;
}

.cmp-button__icon + .cmp-button__text {
  margin: 0 var(--spacing-xxs);
}

/* sticky */
@media (--breakpoint-down-md) {
  .cmp-header--menu-open .cmp-header__navigation .button--sticky {
    display: none;
  }
}

.cmp-button--sticky {
  z-index: 5;
  cursor: pointer;
}

.cmp-button--primary-positive .cmp-button.cmp-button--sticky {
  padding: var(--spacing-sm);
  box-shadow: var(--box-shadow-xs);
}

.aem-AuthorLayer-Edit .pagestage .cmp-button--sticky {
  display: none;
}

.cmp-header__navigation .button--sticky .cmp-button--sticky.cmp-button--is-fixed {
  position: fixed;
  bottom: auto;
}

@media (--breakpoint-header-down-md) {
  .cmp-header-rebrand .cmp-header__navigation .cmp-button--primary-positive.button--sticky {
    display: block;
  }

  .cmp-header-rebrand .cmp-header__navigation .button--sticky .cmp-button--sticky {
    position: fixed;
    bottom: 0;
    padding: var(--spacing-sm);
    margin: var(--spacing-xs);
  }
}

.cmp-button.cmp-button--link,
.cmp-button.cmp-button--imgChat,
.cmp-button.cmp-button--chat {
  display: none;
}

.cmp-button.cmp-button--fixed {
  &.cmp-button--link,
  &.cmp-button--imgChat,
  &.cmp-button--chat {
    position: fixed;
    display: flex;
  }
}

.cmp-button.cmp-button--imgChat > img,
.cmp-button.cmp-button--chat {
  border-radius: var(--border-xl);
}

@media (--breakpoint-up-sm) {
  .cmp-button.cmp-button--sticky.cmp-button--fixed.cmp-button--chat {
    right: var(--spacing-xxxl);
    bottom: var(--spacing-xxxl);
  }
}

@media (--breakpoint-up-xxl) {
  .cmp-button.cmp-button--link,
  .cmp-button.cmp-button--imgChat {
    margin-right: 0;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-button--sticky.cmp-button--fixed.cmp-button--chat {
    bottom: var(--spacing-lg);
    right: var(--spacing-lg);
  }

  .cmp-button.cmp-button--link,
  .cmp-button.cmp-button--imgChat {
    position: fixed;
    bottom: var(--spacing-lg);
    right: var(--spacing-lg);
    border-radius: var(--border-xl);
  }
}

@media #{$mq-2} {
  .aem-Grid {
    &.aem-Grid--md--12 {
      & > .aem-GridColumn {
        &.aem-GridColumn--offset--md--5 {
          &.cmp-form-button--wrapper {
            &.cmp-button--primary-positive {
              width: 100%;
              margin-left: 0;
              display: flex;
            }
          }
        }
      }
    }
  }
}

/* stylelint-disable-next-line selector-id-pattern, selector-max-id */
.cmp-button.cmp-button--imgChat:has(#ECC-ChatButton)::after {
  padding-top: 0;
}

/* Shape - Policies */
.cmp-button--round {
  background-color: var(--color-white);
  border-radius: 50%;
  height: var(--spacing-xxl); /* 40 */
  width: var(--spacing-xxl); /* 40 */
  line-height: var(--line-height-xs);
}

.button.cmp-button--round.cmp-button--round.cmp-button--round.cmp-button--round {
  width: 3rem;
  height: 3rem;
}

.cmp-button--round .cmp-button .cmp-button__icon {
  color: var(--color-black);
  margin: 0;
}

.cmp-button--round .cmp-button__text {
  display: none;
}

/* Visual style - Policies */
.cmp-button--primary-positive .cmp-button,
.cmp-button--primary-positive .cmp-form-button {
  background-color: var(--color-primary-500);
  border: var(--border-xs) solid var(--color-primary-500);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-primary-700);
  }

  &:active {
    background-color: var(--color-primary-800);
  }

  &:disabled {
    background-color: var(--color-black-op-35);
    border: var(--border-xs) solid var(--color-black-op-35);
    color: var(--color-white-op-60);
  }
}

.cmp-button--primary-negative .cmp-button {
  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-white);
  color: var(--color-primary-500);

  &:hover {
    color: var(--color-primary-700);
  }

  &:active {
    background-color: var(--color-primary-100);
    border: none;
    color: var(--color-primary-800);
  }

  &:disabled {
    background-color: var(--color-white-op-35);
    border: var(--border-xs) solid var(--color-white-op-35);
    color: var(--color-black-op-35);
  }
}

.cmp-button--primary-inactive .cmp-button {
  background-color: var(--color-black-op-35);
  border: var(--border-xs) solid var(--color-black-op-35);
  color: var(--color-white-op-60);
}

.cmp-button--secondary-positive .cmp-button {
  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-primary-500);
  color: var(--color-primary-500);

  &:hover {
    outline: var(--border-s) solid var(--color-primary-700);
    border-color: transparent;
    color: var(--color-primary-700);
  }

  &:active {
    background-color: var(--color-primary-100);
    border: var(--border-xs) solid var(--color-primary-800);
    color: var(--color-primary-800);
  }

  &:disabled {
    border: var(--border-xs) solid var(--color-black-op-35);
    color: var(--color-black-op-35);
  }
}

.cmp-button--secondary-negative .cmp-button {
  background-color: var(--color-primary-500);
  border: var(--border-xs) solid var(--color-gray-50);
  color: var(--color-gray-50);

  &:hover {
    border: var(--border-s) solid var(--color-white);
    color: var(--color-white);
  }

  &:active {
    outline: var(--border-xs) solid var(--color-white);
    border-color: transparent;
    color: var(--color-white);
  }

  &:disabled {
    border: var(--border-xs) solid var(--color-white-op-60);
    color: var(--color-white-op-60);
  }
}

.cmp-button--secondary-inactive .cmp-button {
  background-color: transparent;
  border: var(--border-xs) solid var(--color-white-op-60);
  color: var(--color-white-op-60);
}

.cmp-button--primary-positive .cmp-button,
.cmp-button--primary-positive .cmp-form-button,
.cmp-button--primary-negative .cmp-button,
.cmp-button--primary-inactive .cmp-button,
.cmp-button--secondary-positive .cmp-button,
.cmp-button--secondary-negative .cmp-button,
.cmp-button--secondary-inactive .cmp-button {
  padding: var(--spacing-xs) var(--spacing-md); /* 8px 16px */
  min-height: toRem(40px);
}

.cmp-button--primary-positive .cmp-button,
.cmp-button--primary-positive .cmp-form-button,
.cmp-button--primary-negative .cmp-button {
  @include transition-transform;

  &:hover {
    @include link-hover;
  }
}

.cmp-button--primary-positive,
.cmp-button--primary-negative,
.cmp-button--primary-inactive,
.cmp-button--secondary-positive,
.cmp-button--secondary-negative,
.cmp-button--secondary-inactive {
  .cmp-button,
  .cmp-form-button {
    &:hover,
    &:active {
      .cmp-button__text {
        text-decoration: none;
      }
    }

    &:hover .cmp-button__text {
      transform: scale(0.98);
    }
  }
}

/* Specific to iOS devices: fix active state on buttons */
@supports (-webkit-touch-callout: none) {
  .cmp-button--primary-positive .cmp-button:hover,
  .cmp-button--primary-positive .cmp-form-button:hover,
  .cmp-button--primary-negative .cmp-button:hover {
    @include link-hover;
  }
}

.cmp-button--primary-positive .cmp-button__link-icon::after,
.cmp-button--primary-negative .cmp-button__link-icon::after,
.cmp-button--primary-inactive .cmp-button__link-icon::after,
.cmp-button--secondary-positive .cmp-button__link-icon::after,
.cmp-button--secondary-negative .cmp-button__link-icon::after,
.cmp-button--secondary-inactive .cmp-button__link-icon::after {
  content: '';
}

.cmp-button--primary-negative .cmp-button:hover,
.cmp-button--primary-negative .cmp-form-button:hover {
  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-white);
  color: var(--color-primary-700);
}

/* footer */
.footer .cmp-button:hover {
  color: white;
}

.footer .cmp-button:hover .cmp-button__text {
  text-decoration: none;
}

/* backwards compatible icons */
.cmp-button__icon--arrow_down::before {
  content: var(--icon-keyboard_arrow_down);
}

.cmp-button__icon--arrow_left::before {
  content: var(--icon-keyboard_arrow_left);
}

.cmp-button__icon--arrow_right::before {
  content: var(--icon-keyboard_arrow_right);
}

.cmp-button__icon--arrow_up::before {
  content: var(--icon-keyboard_arrow_up);
}

.cmp-button__icon--login::before {
  content: var(--icon-login);
}

.cmp-button.link_external .cmp-button__icon,
.cmp-button .icon-link {
  display: none;
}

.cmp-button .icon-link {
  display: none;
}

.cmp-button.link_external .icon-link {
  display: inline-flex;
}

.cmp-button__inline-svg-icon {
  margin-right: var(--spacing-xxs);
  margin-left: calc(-1 * var(--spacing-xs));
}
