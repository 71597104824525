/*
  Extend background to fullWidth if breakpoint above XXL (1920px)
*/

@mixin fullWidthBackgroundColor($position) {
  position: $position;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
}

@mixin button {
  @include bold-lg;

  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-md);

  @include transition-transform;
}

@mixin button-primary {
  @include button;

  background-color: var(--color-primary-500);
  color: var(--color-white);
  border: var(--border-xs) solid var(--color-primary-500);

  &:hover {
    background-color: var(--color-primary-700);

    @include link-hover;
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-primary-500);
      transform: none;
    }
  }

  &:active {
    background-color: var(--color-primary-800);
  }
}

@mixin button-primary-disabled {
  @include button;

  background-color: var(--color-black-op-35);
  color: var(--color-white-op-60);
  pointer-events: none;
}

@mixin button-secondary {
  @include button;

  background-color: var(--color-white);
  outline: var(--border-xs) solid var(--color-primary-500);
  color: var(--color-primary-500);

  &:hover {
    outline: var(--border-s) solid var(--color-primary-700);
    color: var(--color-primary-700);
  }

  @media (hover: none) {
    &:hover {
      outline: var(--border-xs) solid var(--color-primary-500);
      color: var(--color-primary-500);
    }
  }

  &:active {
    background-color: var(--color-primary-100);
    outline: var(--border-xs) solid var(--color-primary-800);
    color: var(--color-primary-800);
  }
}

@mixin button-secondary-disabled {
  @include button;

  background-color: var(--color-white);
  border-color: var(--color-black-op-35);
  color: var(--color-black-op-35);
  pointer-events: none;
}

@mixin button-primary-inverted {
  @include button;

  background-color: var(--color-white);
  color: var(--color-primary-500);

  &:hover {
    color: var(--color-primary-700);

    @include link-hover;
  }

  @media (hover: none) {
    &:hover {
      color: var(--color-primary-500);
      transform: none;
    }
  }

  &:active {
    color: var(--color-primary-800);
    background-color: var(--color-primary-100);
  }
}

@mixin button-primary-inverted-disabled {
  @include button;

  background-color: var(--color-white-op-35);
  color: var(--color-black-op-35);
  pointer-events: none;
}

@mixin button-secondary-inverted {
  @include button;

  background-color: transparent;
  outline: var(--border-xs) solid var(--color-white);
  color: var(--color-white);

  &:hover {
    outline: var(--border-s) solid var(--color-white);
  }

  @media (hover: none) {
    &:hover {
      outline: var(--border-xs) solid var(--color-white);
    }
  }

  &:active {
    outline: var(--border-xs) solid var(--color-white);
    background-color: var(--color-black-op-35);
  }
}

@mixin button-secondary-inverted-disabled {
  @include button;

  background-color: transparent;
  border-color: var(--color-white-op-60);
  color: var(--color-white-op-60);
  pointer-events: none;
}

@mixin gridColumns($columns) {
  grid-template-columns: repeat($columns, 1fr);
}

@mixin remove-grid-content($class-names...) {
  main .aem-GridColumn%className {
    > *::after {
      content: none;
    }
  }
  #{$class-names} {
    @extend %className;
  }
}

@mixin transition-transform {
  transition: transform 0.15s ease-in-out;
}

@mixin link-icon-right {
  transform: translateX(var(--spacing-xxs));
}

@mixin link-icon-left {
  transform: translateX(calc(-1 * var(--spacing-xxs)));
}

@mixin link-icon-up {
  transform: translateY(calc(-1 * var(--spacing-xxs)));
}

@mixin link-icon-down {
  transform: translateY(var(--spacing-xxs));
}

@mixin link-icon-hover {
  transform: scale(1.16);
}

@mixin link-hover {
  transform: scale(1.02);
}
